// import React, { useState, useEffect } from "react";
// import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
// import {
//   Dialog,
//   DialogContent,
//   DialogActions,
//   DialogTitle,
//   FormControlLabel,
//   Checkbox,
// } from "@mui/material";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import Select from "react-select";
// import { fetchBlockedDates, formatDate } from "./utils";

// const BOOKING_TYPES = {
//   HALF_DAY_MORNING: {
//     label: "Half Day Morning (6 AM - 2 PM)",
//     value: "half_day_morning",
//     startTime: "06:00:00",
//     endTime: "14:00:00",
//   },
//   HALF_DAY_EVENING: {
//     label: "Half Day Evening (4 PM - 11 PM)",
//     value: "half_day_evening",
//     startTime: "16:00:00",
//     endTime: "23:00:00",
//   },
//   FULL_DAY: {
//     label: "Full Day (6 AM - 11 PM)",
//     value: "full_day",
//     startTime: "06:00:00",
//     endTime: "23:00:00",
//   },
// };

// const COLOR_MAP = {
//   half_day_morning: "#F6C581",
//   half_day_evening: "#9BE079",
//   full_day: "#BBBBBB",
//   new_event: "#FFC0CB",
// };

// const LeadEvents = ({ mandapId, onUpdateEvents, LeadEventData = [] }) => {
//   const [events, setEvents] = useState(LeadEventData);
//   const [dialogOpen, setDialogOpen] = useState(false);
//   const [selectedDate, setSelectedDate] = useState(null);
//   const [selectedBookingTypes, setSelectedBookingTypes] = useState([]);
//   const [eventSubtype, setEventSubtype] = useState("Engagement");
//   const [blockedDates, setBlockedDates] = useState([]);

//   useEffect(() => {
//     setEvents(LeadEventData);
//   }, [LeadEventData]);

//   useEffect(() => {
//     if (!mandapId) {
//       console.warn("Skipping fetch because mandapId is invalid.");
//       return; // Exit early if mandapId is null, undefined, or empty
//     }

//     const fetchData = async () => {
//       try {
//         const data = await fetchBlockedDates(mandapId);
//         setBlockedDates(data || []);
//       } catch (error) {
//         console.error("Error fetching blocked dates:", error);
//       }
//     };

//     fetchData();
//   }, [mandapId]);

//   const determineBookingType = () => {
//     if (
//       selectedBookingTypes.includes("half_day_morning") &&
//       selectedBookingTypes.includes("half_day_evening")
//     ) {
//       return "full_day";
//     }
//     return selectedBookingTypes[0] || null; // Default to the first selected option
//   };

//   const handleCheckboxChange = (event) => {
//     const { value, checked } = event.target;

//     setSelectedBookingTypes((prev) => {
//       const updatedTypes = checked
//         ? [...prev, value]
//         : prev.filter((type) => type !== value);

//       // If no booking types are selected, remove the event for this date
//       if (updatedTypes.length === 0) {
//         setEvents((prevEvents) =>
//           prevEvents.filter(
//             (e) =>
//               e.event_date_from !== formatDate(selectedDate) ||
//               e.booking_type !== null
//           )
//         );
//       }

//       console.log(updatedTypes, ">>");
//       return updatedTypes;
//     });
//   };

//   const handleAddEvent = () => {
//     if (!selectedDate) return alert("Please select a valid date.");

//     const bookingType = determineBookingType();
//     const formattedDate = formatDate(selectedDate);

//     // Check if event for the selected date already exists
//     const existingEventIndex = events.findIndex(
//       (event) => event.event_date_from === formattedDate
//     );

//     if (existingEventIndex !== -1) {
//       // If event exists, update it instead of adding a new one
//       const updatedEvents = [...events];
//       updatedEvents[existingEventIndex] = {
//         ...updatedEvents[existingEventIndex],
//         booking_type: bookingType || null,
//         start_time: bookingType
//           ? BOOKING_TYPES[bookingType.toUpperCase()].startTime
//           : "",
//         end_time: bookingType
//           ? BOOKING_TYPES[bookingType.toUpperCase()].endTime
//           : "",
//       };

//       // If booking_type is null, remove the event for this date
//       if (updatedEvents[existingEventIndex].booking_type === null) {
//         updatedEvents.splice(existingEventIndex, 1); // Remove event if no valid booking_type
//       }

//       setEvents(updatedEvents);
//       onUpdateEvents(updatedEvents);
//     } else {
//       // If no event exists, create a new one
//       const { startTime, endTime } = bookingType
//         ? BOOKING_TYPES[bookingType.toUpperCase()] || {}
//         : {};

//       const newEvent = {
//         event_type: "Wedding",
//         event_subtype: eventSubtype,
//         event_date_from: formattedDate,
//         event_date_to: formattedDate, // Same start and end for a single date
//         start_time: startTime || "",
//         end_time: endTime || "",
//         booking_type: bookingType || null,
//       };

//       const updatedEvents = [...events, newEvent];
//       setEvents(updatedEvents);
//       onUpdateEvents(updatedEvents);
//     }

//     setDialogOpen(false);
//   };

//   const isBlocked = (date) =>
//     blockedDates.some(
//       (blocked) =>
//         new Date(blocked.date).toDateString() === date?.toDateString() &&
//         blocked.is_blocked
//     );

//   const getDateStyles = (date) => {
//     if (selectedDate && date?.toDateString() === selectedDate?.toDateString()) {
//       return { backgroundColor: "#007bff", color: "#fff" };
//     }

//     const hasFullDayBlock = blockedDates.some((blocked) => {
//       return (
//         new Date(blocked.date).toDateString() === date?.toDateString() &&
//         blocked.booking_type === "half_day_morning" &&
//         blockedDates.some(
//           (e) =>
//             e.date === blocked.date && e.booking_type === "half_day_evening"
//         )
//       );
//     });

//     // If both morning and evening half-day events are blocked, style it as a full day (gray)
//     if (hasFullDayBlock) {
//       return { backgroundColor: COLOR_MAP.full_day, color: "#fff" };
//     }

//     if (
//       events.some(
//         (event) =>
//           new Date(event.event_date_from).toDateString() ===
//           date?.toDateString()
//       )
//     ) {
//       return { backgroundColor: COLOR_MAP.new_event, color: "#fff" };
//     }

//     const blockedDate = blockedDates.find(
//       (blocked) =>
//         new Date(blocked.date).toDateString() === date?.toDateString()
//     );

//     if (blockedDate && blockedDate.is_blocked) {
//       const color = COLOR_MAP[blockedDate.booking_type] || "#FF5733";
//       return { backgroundColor: color, color: "#fff" };
//     }

//     return { backgroundColor: "#ffffff", color: "#000000" };
//   };

//   return (
//     <div style={{ display: "flex", width: "100%", marginLeft: "10px" }}>
//       <div>
//         <OverlayTrigger
//           placement="top"
//           overlay={
//             mandapId === null ? (
//               <Tooltip id="tooltip-disabled">Please select Mandap</Tooltip>
//             ) : (
//               <></>
//             )
//           }
//         >
//           <span className="d-inline-block">
//             <Button
//               disabled={mandapId === null}
//               variant="outline-primary"
//               onClick={() => setDialogOpen(true)}
//             >
//               Allocate Dates
//             </Button>
//           </span>
//         </OverlayTrigger>
//         <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
//           <DialogTitle>Select Date and Booking Type</DialogTitle>
//           <DialogContent>
//             <div className="d-flex ">
//               <DatePicker
//                 selected={selectedDate}
//                 onChange={(date) => {
//                   setSelectedDate(date);
//                   setSelectedBookingTypes(
//                     events
//                       .filter(
//                         (event) => event.event_date_from === formatDate(date)
//                       )
//                       .map((event) => event.booking_type)
//                   );
//                 }}
//                 inline
//                 renderDayContents={(day, date) => {
//                   const style = getDateStyles(date);
//                   return <div style={style}>{day}</div>;
//                 }}
//               />
//               <div style={{ marginLeft: "20px" }}>
//                 <ul>
//                   <li style={{ color: COLOR_MAP.half_day_morning }}>
//                     Half Day Morning
//                   </li>
//                   <li style={{ color: COLOR_MAP.half_day_evening }}>
//                     Half Day Evening
//                   </li>
//                   <li style={{ color: COLOR_MAP.full_day }}>Full Day</li>
//                   <li style={{ color: COLOR_MAP.new_event }}>Booked Events</li>
//                 </ul>
//               </div>
//             </div>

//             <div>
//               <label>Event Subtype:</label>
//               <Select
//                 value={{ label: eventSubtype, value: eventSubtype }}
//                 onChange={(e) => setEventSubtype(e.value)}
//                 options={[
//                   { value: "Engagement", label: "Engagement" },
//                   { value: "Reception", label: "Reception" },
//                   { value: "Other", label: "Other" },
//                 ]}
//               />
//             </div>
//             <div>
//               <label>Booking Type:</label>
//               <div>
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={selectedBookingTypes.includes(
//                         "half_day_morning"
//                       )}
//                       onChange={handleCheckboxChange}
//                       value="half_day_morning"
//                     />
//                   }
//                   label={BOOKING_TYPES.HALF_DAY_MORNING.label}
//                 />
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={selectedBookingTypes.includes(
//                         "half_day_evening"
//                       )}
//                       onChange={handleCheckboxChange}
//                       value="half_day_evening"
//                     />
//                   }
//                   label={BOOKING_TYPES.HALF_DAY_EVENING.label}
//                 />
//               </div>
//             </div>
//           </DialogContent>
//           <DialogActions>
//             <Button variant="secondary" onClick={() => setDialogOpen(false)}>
//               Cancel
//             </Button>
//             <Button variant="primary" onClick={handleAddEvent}>
//               Add Event
//             </Button>
//           </DialogActions>
//         </Dialog>
//       </div>
//     </div>
//   );
// };

// export default LeadEvents;

import React, { useState, useEffect } from "react";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { fetchBlockedDates, formatDate } from "./utils";
import { toast } from "react-toastify";

// Define event types and their corresponding subtypes
const EVENT_TYPES = {
  Wedding: [
    { value: "Engagement", label: "Engagement" },
    { value: "Reception", label: "Reception" },
    { value: "Marriage", label: "Marriage" },
    { value: "Haldi", label: "Haldi" },
    { value: "Sangeet", label: "Sangeet" },
  ],
  "Non Wedding": [
    { value: "Birthday", label: "Birthday" },
    { value: "Puberty", label: "Puberty" },
    { value: "Naming Ceremony", label: "Naming Ceremony" },
    { value: "Anniversary", label: "Anniversary" },
    { value: "Office Meeting", label: "Office Meeting" },
    { value: "Others", label: "Others" },
  ],
};

const BOOKING_TYPES = {
  HALF_DAY_MORNING: {
    label: "Half Day Morning (6 AM - 2 PM)",
    value: "half_day_morning",
    startTime: "06:00:00",
    endTime: "14:00:00",
  },
  HALF_DAY_EVENING: {
    label: "Half Day Evening (4 PM - 11 PM)",
    value: "half_day_evening",
    startTime: "16:00:00",
    endTime: "23:00:00",
  },
  FULL_DAY: {
    label: "Full Day (6 AM - 11 PM)",
    value: "full_day",
    startTime: "06:00:00",
    endTime: "23:00:00",
  },
};

const COLOR_MAP = {
  half_day_morning: "#F6C581",
  half_day_evening: "#9BE079",
  full_day: "#BBBBBB",
  new_event: "#FFC0CB",
};

const LeadEvents = ({ mandapId, onUpdateEvents, LeadEventData = [] }) => {
  const [events, setEvents] = useState(LeadEventData);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedBookingTypes, setSelectedBookingTypes] = useState([]);
  const [eventType, setEventType] = useState("Wedding");
  const [eventSubtype, setEventSubtype] = useState("Engagement");
  const [blockedDates, setBlockedDates] = useState([]);
  const tokenConfig = {
    headers: { Authorization: `Token ${sessionStorage.getItem("tokenKey")}` },
  };

  useEffect(() => {
    setEvents(LeadEventData);
  }, [LeadEventData]);

  useEffect(() => {
    if (!mandapId) {
      console.warn("Skipping fetch because mandapId is invalid.");
      return;
    }

    const fetchData = async () => {
      try {
        const data = await fetchBlockedDates(mandapId, tokenConfig);
        setBlockedDates(data || []);
      } catch (error) {
        console.error("Error fetching blocked dates:", error);
      }
    };

    fetchData();
  }, [mandapId]);

  const determineBookingType = () => {
    if (
      selectedBookingTypes.includes("half_day_morning") &&
      selectedBookingTypes.includes("half_day_evening")
    ) {
      return "full_day";
    }
    return selectedBookingTypes[0] || null;
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    setSelectedBookingTypes((prev) => {
      const updatedTypes = checked
        ? [...prev, value]
        : prev.filter((type) => type !== value);

      if (updatedTypes.length === 0) {
        setEvents((prevEvents) =>
          prevEvents.filter(
            (e) =>
              e.event_date_from !== formatDate(selectedDate) ||
              e.booking_type !== null
          )
        );
      }

      return updatedTypes;
    });
  };

  const handleAddEvent = () => {
    if (!selectedDate) return toast.error("Please select a valid date.");

    const bookingType = determineBookingType();

    
    const formattedDate = formatDate(selectedDate);

    const existingEventIndex = events.findIndex(
      (event) => event.event_date_from === formattedDate
    );

    if (existingEventIndex !== -1) {
      const updatedEvents = [...events];
      updatedEvents[existingEventIndex] = {
        ...updatedEvents[existingEventIndex],
        booking_type: bookingType || null,
        start_time: bookingType
          ? BOOKING_TYPES[bookingType.toUpperCase()].startTime
          : "",
        end_time: bookingType
          ? BOOKING_TYPES[bookingType.toUpperCase()].endTime
          : "",
      };

      if (updatedEvents[existingEventIndex].booking_type === null) {
        updatedEvents.splice(existingEventIndex, 1);
      }

      setEvents(updatedEvents);
      onUpdateEvents(updatedEvents);
    } else {
      if (!bookingType) {
        return toast.error("Please select a valid booking type.");
      }
      const { startTime, endTime } = bookingType
        ? BOOKING_TYPES[bookingType.toUpperCase()] || {}
        : {};

      const newEvent = {
        event_type: eventType,
        event_subtype: eventSubtype,
        event_date_from: formattedDate,
        event_date_to: formattedDate,
        start_time: startTime || "",
        end_time: endTime || "",
        booking_type: bookingType || null,
      };

      const updatedEvents = [...events, newEvent];
      setEvents(updatedEvents);
      onUpdateEvents(updatedEvents);
    }

    setDialogOpen(false);
  };

  // const isBlocked = (date) =>
  //   blockedDates.some(
  //     (blocked) =>
  //       new Date(blocked.date).toDateString() === date?.toDateString() &&
  //       blocked.is_blocked
  //   );

  const getDateStyles = (date) => {
    if (selectedDate && date?.toDateString() === selectedDate?.toDateString()) {
      return { backgroundColor: "#007bff", color: "#fff" };
    }

    const hasFullDayBlock = blockedDates.some((blocked) => {
      return (
        new Date(blocked.date).toDateString() === date?.toDateString() &&
        blocked.booking_type === "half_day_morning" &&
        blockedDates.some(
          (e) =>
            e.date === blocked.date && e.booking_type === "half_day_evening"
        )
      );
    });

    if (hasFullDayBlock) {
      return { backgroundColor: COLOR_MAP.full_day, color: "#fff" };
    }

    if (
      events.some(
        (event) =>
          new Date(event.event_date_from).toDateString() ===
          date?.toDateString()
      )
    ) {
      return { backgroundColor: COLOR_MAP.new_event, color: "#fff" };
    }

    const blockedDate = blockedDates.find(
      (blocked) =>
        new Date(blocked.date).toDateString() === date?.toDateString()
    );

    if (blockedDate && blockedDate.is_blocked) {
      const color = COLOR_MAP[blockedDate.booking_type] || "#FF5733";
      return { backgroundColor: color, color: "#fff" };
    }

    return { backgroundColor: "#ffffff", color: "#000000" };
  };

  const handleEventTypeChange = (e) => {
    setEventType(e.value);
    setEventSubtype(EVENT_TYPES[e.value][0]?.value); // Default to the first subtype of the selected event type
  };

  return (
    <div style={{ display: "flex", width: "100%", marginLeft: "10px" }}>
      <div>
        <OverlayTrigger
          placement="top"
          overlay={
            mandapId === null ? (
              <Tooltip id="tooltip-disabled">Please select Mandap</Tooltip>
            ) : (
              <></>
            )
          }
        >
          <span className="d-inline-block">
            <Button
              disabled={mandapId === null}
              variant="outline-primary"
              onClick={() => setDialogOpen(true)}
            >
              Allocate Dates
            </Button>
          </span>
        </OverlayTrigger>
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle>Allocate Dates</DialogTitle>

          <DialogContent>
            <div className="d-flex align-items-start">
              {/* Date Picker Section */}
              <div>
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => {
                    setSelectedDate(date);
                    setSelectedBookingTypes(
                      events
                        .filter(
                          (event) => event.event_date_from === formatDate(date)
                        )
                        .map((event) => event.booking_type)
                    );
                  }}
                  inline
                  renderDayContents={(day, date) => {
                    const style = getDateStyles(date);
                    return <div style={style}>{day}</div>;
                  }}
                />
              </div>

              {/* Legend Section */}
              <div style={{ marginLeft: "20px", minWidth: "150px" }}>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: COLOR_MAP.half_day_morning,
                        marginRight: "10px",
                        borderRadius: "4px",
                      }}
                    ></div>
                    Half Day Morning
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: COLOR_MAP.half_day_evening,
                        marginRight: "10px",
                        borderRadius: "4px",
                      }}
                    ></div>
                    Half Day Evening
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: COLOR_MAP.full_day,
                        marginRight: "10px",
                        borderRadius: "4px",
                      }}
                    ></div>
                    Full Day
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: COLOR_MAP.new_event,
                        marginRight: "10px",
                        borderRadius: "4px",
                      }}
                    ></div>
                    Dates blocked by Lead
                  </li>
                </ul>
              </div>
            </div>

            {/* Event Type Section */}
            <div style={{ marginTop: "20px" }}>
              <label
                style={{
                  display: "block",
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}
              >
                Event Type:
              </label>
              <Select
                value={{ label: eventType, value: eventType }}
                onChange={handleEventTypeChange}
                options={[
                  { value: "Wedding", label: "Wedding" },
                  { value: "Non Wedding", label: "Non Wedding" },
                ]}
              />
            </div>

            {/* Event Subtype Section */}
            <div style={{ marginTop: "20px" }}>
              <label
                style={{
                  display: "block",
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}
              >
                Event Subtype:
              </label>
              <Select
                value={{ label: eventSubtype, value: eventSubtype }}
                onChange={(e) => setEventSubtype(e.value)}
                options={EVENT_TYPES[eventType]}
              />
            </div>

            {/* Booking Type Section */}
            <div style={{ marginTop: "20px" }}>
              <label
                style={{
                  display: "block",
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}
              >
                Booking Type:
              </label>
              <div className="d-flex flex-column">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedBookingTypes.includes(
                        "half_day_morning"
                      )}
                      onChange={handleCheckboxChange}
                      value="half_day_morning"
                    />
                  }
                  label={BOOKING_TYPES.HALF_DAY_MORNING.label}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedBookingTypes.includes(
                        "half_day_evening"
                      )}
                      onChange={handleCheckboxChange}
                      value="half_day_evening"
                    />
                  }
                  label={BOOKING_TYPES.HALF_DAY_EVENING.label}
                />
              </div>
            </div>
          </DialogContent>

          <DialogActions>
            <Button variant="secondary" onClick={() => setDialogOpen(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleAddEvent}>
              Add Event
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default LeadEvents;
