import axios from "axios";
import config from "../../../config";
import { toast } from "react-toastify";

// Token configuration for requests
// export const tokenConfig = {
//   headers: { Authorization: `Token ${sessionStorage.getItem("tokenKey")}` },
// };

export const formatDate = (date) => {
  if (!date) return ""; // Return an empty string if no date is provided

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

// form fields
export const formFields = [
  { name: "city", label: "city", type: "text", required: false },
  { name: "address", label: "Address", type: "text", required: false },
  { name: "location", label: "Location", type: "text", required: false },
  { name: "pincode", label: "Pincode", type: "text", required: false },
  {
    name: "customerName",
    label: "Customer Name",
    type: "text",
    required: true,
  },

  { name: "email", label: "Email", type: "email", required: false },
  {
    name: "contactNumber",
    label: "Contact Number",
    type: "number",
    required: true,
  },
  {
    name: "address",
    label: "Customer Address",
    type: "text",
    required: false,
  },
  {
    name: "pincode",
    label: "Customer Pincode",
    type: "number",
    required: false,
  },
  { name: "remarks", label: "Remarks", type: "textarea", required: false },
];

// Toast attributes configuration
export const toastAttr = {
  theme: "colored",
  position: "top-center",
  autoClose: 1000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

// Fetch options for dropdowns (KAE or Mandap)
export const fetchOptions = async (entityType, query, tokenConfig) => {
  try {
    const url =
      entityType === "kae"
        ? `${config.service}/accounts/agentusers/list/?search=${query}`
        : `${config.service}/masters/vendor_mandap/?search=${query}`;

    const response = await axios.get(url, tokenConfig);

    return response.data.results.map((item) => {
      if (entityType === "kae") {
        return {
          id: item.id,
          name: item?.user_id?.username,
          email: item.user_id?.email || "",
          // contactNumber: item.mobile || "",
          // phone_number: item.phone_number || "",
          // status: item.status || "Unknown",
        };
      } else {
        return {
          id: item.id,
          name: item.mandap_name || "",
          city: item.city_name || "",
          address: item.address_1 || "",
          location: item.area_name || "",
          pincode: item.pincode || "",
        };
      }
    });
  } catch (error) {
    console.error(`Error fetching ${entityType} options:`, error);
    return [];
  }
};

// Fetch initial dropdown data for KAE and Mandap
export const fetchInitialData = async (tokenConfig) => {
  try {
    const [kaeResponse, mandapResponse] = await Promise.all([
      axios.get(`${config.service}/accounts/agentusers/list/`, tokenConfig),
      axios.get(`${config.service}/masters/vendor_mandap/`, tokenConfig),
    ]);

    return {
      kaeOptions: kaeResponse?.data?.results?.map((kae) => ({
        id: kae?.id,
        name: kae?.user_id?.username,
        email: kae?.user_id?.email,
      })),
      mandapOptions: mandapResponse?.data?.results?.map((mandap) => ({
        id: mandap.id,
        name: mandap.mandap_name,
        city: mandap.city_name,
        address: mandap.address_1,
        location: mandap.area_name,
        pincode: mandap.pincode,
      })),
    };
  } catch (error) {
    console.error("Error fetching initial dropdown data:", error);
    return { kaeOptions: [], mandapOptions: [] };
  }
};

// Fetch edit data for the form
export const fetchEditData = async (leadId, tokenConfig) => {
  try {
    const response = await axios.get(
      `${config.service}/masters/offline_leads/${leadId}/`,
      tokenConfig
    );
    const leadData = response.data;

    return {
      id: leadData.id,
      // kae: { ...leadData.kae },
      kae: {
        id: leadData?.kae?.id,
        name: leadData?.kae?.user_id?.username,
        email: leadData?.kae?.user_id?.email,
      },
      mandap: {
        id: leadData.mandap.id,
        name: leadData.mandap.mandap_name,
        city: leadData.mandap.city_name,
        location: leadData.mandap.area_name,
        address: leadData.mandap.address_1,
        pincode: leadData.mandap.pincode,
      },
      address: leadData.address,
      pincode: leadData.pincode,
      customerName: leadData.customer_name,
      contactNumber: leadData.contact_number,
      email: leadData.email,
      remarks: leadData.remarks,
      leadEvents: leadData.lead_events,
    };
  } catch (error) {
    console.error("Error fetching edit data:", error);
    return null;
  }
};

export const fetchBlockedDates = async (mandapId, tokenConfig) => {
  try {
    const response = await axios.get(
      `${config.service}/vendor/mandap-allocation/${mandapId}/`,
      tokenConfig
    );

    if (response.status === 200) {
      const transformedData = response.data
        .filter((item) => item.is_blocked)
        .map((blockedDate) => {
          const event = {
            event_type: "",
            event_subtype: "",
            event_date_from: blockedDate.date,
            event_date_to: blockedDate.date,
            is_blocked: true,
            date: blockedDate.date,
            start_time: "06:00:00",
            end_time: "23:00:00",
            booking_type: blockedDate.timing,
          };

          // Adjust start and end time based on the timing of the blocked date
          if (blockedDate.timing === "half_day_evening") {
            event.start_time = "16:00:00";
            event.end_time = "23:00:00";
          } else if (blockedDate.timing === "half_day_morning") {
            event.start_time = "06:00:00";
            event.end_time = "14:00:00";
          } else if (blockedDate.timing === "full_day") {
            event.start_time = "06:00:00";
            event.end_time = "23:00:00";
          }

          return event;
        });

      // Return the transformed data
      return transformedData || [];
    } else {
      toast.error("Failed to fetch blocked dates. Please try again later.", {
        ...toastAttr,
        autoClose: 4000,
      });
      return [];
    }
  } catch (err) {
    console.error("Error fetching blocked dates:", err);

    toast.error(
      "An error occurred while fetching blocked dates. Please try again.",
      {
        ...toastAttr,
        autoClose: 4000,
      }
    );

    return [];
  }
};

export const checkAvailability = async (
  event,
  mandapId,
  isEditMode,
  tokenConfig
) => {
  const payload = {
    booking_from: `${event.event_date_from} ${event.start_time}`,
    booking_to: `${event.event_date_from} ${event.end_time}`,
    mandap_id: mandapId,
    slot: event.booking_type,
    date: event.event_date_from,
    current_booking_id: isEditMode ? event.booking_id : undefined,
  };

  try {
    const response = await axios.post(
      `${config.service}/booking/booking/checkavailability`,
      payload,
      { ...tokenConfig }
    );

    return { isAvailable: true, message: response.data.message };
  } catch (error) {
    if (error.response?.data?.status === "400") {
      const blockedTimesRaw = error.response.data.blocked_times;

      // Clean up the blocked_times string
      let blockedTimes = [];
      try {
        blockedTimes = JSON.parse(
          blockedTimesRaw
            .replace(/'/g, '"')
            .replace(/<[^>]*>/g, '"Invalid Mandap ID"')
        );
      } catch (parseError) {
        console.error("Error parsing blocked_times:", parseError);
      }

      return {
        isAvailable: false,
        message: error.response.data.message,
        blockedTimes,
      };
    }

    throw new Error("Error checking availability");
  }
};

// export const detectChanges = (originalEvents, newEvents) => {
//     const updatedEvents = newEvents.filter((event) => {
//       const originalEvent = originalEvents.find((e) => e.id === event.id);
//       return (
//         !originalEvent || // New event
//         originalEvent.event_date_from !== event.event_date_from || // Date changed
//         originalEvent.booking_type !== event.booking_type // Type changed
//       );
//     });

//     const removedEvents = originalEvents.filter(
//       (event) => !newEvents.some((e) => e.id === event.id)
//     );

//     return { updatedEvents, removedEvents };
//   };

export const detectChanges = (originalEvents, currentEvents) => {
  const updatedEvents = currentEvents.filter(
    (current) =>
      !originalEvents.some(
        (original) =>
          original.id === current.id &&
          JSON.stringify(original) === JSON.stringify(current)
      )
  );

  const removedEvents = originalEvents.filter(
    (original) => !currentEvents.some((current) => current.id === original.id)
  );

  return { updatedEvents, removedEvents };
};

export const allocateMandap = async (
  mandapId,
  event,
  isBlocked,
  bookingId,
  tokenConfig
) => {
  const payload = {
    allocated_dates:
      event.booking_type === "full_day"
        ? [
            {
              timing: "half_day_morning",
              is_blocked: isBlocked,
              date: event.event_date_from,
            },
            {
              timing: "half_day_evening",
              is_blocked: isBlocked,
              date: event.event_date_from,
            },
          ]
        : [
            {
              timing: event.booking_type,
              is_blocked: isBlocked,
              date: event.event_date_from,
            },
          ],
    booking_id: bookingId || null,
  };

  const response = await axios.post(
    `${config.service}/vendor/mandap-allocation/${mandapId}/`,
    payload,
    { ...tokenConfig }
  );

  return response.data;
};

export const showToastForUnavailableDates = (date, blockedTimes) => {
  const formattedTimes = blockedTimes
    .map(
      (time) =>
        `Booked from ${new Date(
          time.booked_from
        ).toLocaleTimeString()} to ${new Date(
          time.booked_to
        ).toLocaleTimeString()}`
    )
    .join("\n");

  toast.error(
    `Mandap is not available on ${date}. The following times are booked:\n${formattedTimes}`,
    {
      ...toastAttr,
      autoClose: 5000,
    }
  );
};
